import { Link } from "gatsby";
import * as React from "react";
import WebsiteLayout from "../layouts/WebsiteLayout";

const PrivacyPolicy = (props) => {
  return (
    <main>
        <WebsiteLayout location={props.location} title="Privacy Policy">
        <section className="content-header content-header-1 -blue">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="heading">Privacy Policy</h1>
                </div>
            </div>
        </div>
    </section>
    <section className="content content-2 -bg-grey">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <u><h3>Cookies</h3></u>
                    <p>A cookie is a piece of text sent by our server to your browser, and is usually stored on your local machine. The information is then sent back to our servers when you visit our website, and this allows us to identify and track the web browser.</p>
                    <p>We use both persistent cookies and session cookies to provide our website to you. We use the cookies to keep track of you whilst you are on our website, and also to allow you to use our services.</p>
                    <p>Session cookies are deleted upon you closing the web browser, and persistent cookies are usually stored until you either delete them or they reach their expiry date.</p>
                    <p>We also use Google Analytics to analyse the behaviour and use of our website by visitors. Google Analytics generates statistics we can use to improve the services we offer to you. Google stores this information securely on our behalf and no personal information is stored. Google’s privacy policy is available at <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a></p>
                    <p>Although most browsers allow you to reject the use of cookies, this can have a negative impact on the usability of many websites, including this one.</p>
                    <br/>
                    <u><h3>Personal Information</h3></u>
                    <p>When you register for an account, you provide us with your name and details (including email address, business name, first and last name). We use this to:</p>
                    <li>Administer the website;</li>
                    <li>Improve your browsing experience by personalising the website;</li>
                    <li>Enable your use of the services available on the website;</li>
                    <li>Send statements and invoices to you, and collect payments from you;</li>
                    <li>Send you general communications;</li>
                    <li>Send you email notifications which you have requested;</li>
                    <li>Deal with enquiries and complaints made by you or about you relating to the website;</li>
                    <p>We will not without your express consent provide your information to third parties for the purpose of direct marketing.</p>
                    <p>All our website’s financial transactions are handled by our payment services provider, Stripe. You can review the Stripe privacy policy at <a href="https://stripe.com">stripe.com</a>. We share only necessary information with Stripe, in order for them to process payments and in order to deal with complaints and queries relating to such payments.</p>

                    <u><h3>Disclosures</h3></u>

                    <p>We may disclose information about you to any of our employees, as reasonably necessary for the purposes set out in our privacy policy.</p>
                    <p>We may also disclose your personal information:</p>
                    <li>If we are required to do so by law;</li>
                    <li>In connection with legal proceedings or prospective legal proceedings;</li>
                    <li>In order to defend, exercise or establish our legal rights including but not limited to providing information to others for the purposes of fraud prevention and reducing credit risk.</li>
                    <br/>
                    <p>Except as stated in this privacy policy, we will not provide your information to third parties.</p>
                    <u><h3>Security of your information</h3></u>
                    <p>We take reasonable organisational and technical precautions to prevent the loss, misuse, theft or alteration of your personal information.</p>
                    <p>We will store all the personal information you provide to us on our secure servers. These are protected by both physical and technical security, including but not limited to firewalls, password protection, and limited access.</p>
                    <p>All of our servers are hosted inside of the UK and our hosting providers do not have permission to access any data on the hard drives. Hard drives are also encrypted in the result of physical theft.</p>

                    <u><h3>Policy Amendments</h3></u>

                    <p>We may update this privacy policy by posting a new version on our website. You should check this page occasionally to ensure you are still happy with the policy.</p>

                    <u><h3>Your Rights</h3></u>

                    <p>Under GDPR regulations, you are able to request all data we hold about yourself. Please submit all requests via our <Link to={"/contact-us/"}>contact page.</Link></p>
                </div>
            </div>
        </div>
    </section>
        </WebsiteLayout>
    </main>
  )
}

export default PrivacyPolicy;